<template>
    <section class="section">
		<div class="container">
			<center class="welcome">
				<h1>Congratulations</h1>
				<p>We are processing your transaction</p>
				<p>Thank you.</p>
			</center>
		</div>
	</section>
</template>

<script>
// import { getFirestore, doc, setDoc,  } from '@firebase/firestore';
// const db = getFirestore();
export default {
	data(){
		return {
			events: [],
			isLoading: false,
			per_page: 10,
			last_visible: null,
			placeholder: '/img/placeholder.jpg',
			keyword: '',
		}
	},
	// async mounted(){
	// 	if (!('query' in this.$route)) return; 
	// 	const { query } = this.$route;
	// 	if ('reference' in query) {
	// 		const { reference, trxref } = query;
	// 		const payload = { reference, trxref }
	// 		const pst_verification_ref = doc(db, 'paystack_verification', reference);
	// 		await setDoc(pst_verification_ref, payload, { merge: true });
	// 		return;
	// 	}
	// 	if ('transaction_id' in query) {
	// 		const { transaction_id, tx_ref, status } = query;
	// 		const payload = { transaction_id, tx_ref, status }
	// 		const flw_verification_ref = doc(db, 'flutterwave_verification', tx_ref);
	// 		await setDoc(flw_verification_ref, payload, { merge: true });
	// 		return;
	// 	}
	// 	if (!('reference' in query) || !('transaction_id' in query)) return;
	// }
};
</script>
